import { useEffect, useState } from "react"
import { ReactComponent as Question } from './questionMarkInCircle.svg';

export function ChatPrint({roomId, messages, isSort, isResolve}) {
    const [msg, setMsg] = useState([])

    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        setMessagesSort()
    }, [messages, isResolve, isSort])

    useEffect(() => {
        // 1초마다 시간을 업데이트하는 interval 설정
        const intervalId = setInterval(() => {
        setCurrentTime(new Date());
        }, 1000);

        setMessagesSort()

        // 컴포넌트가 언마운트될 때 interval을 정리
        return () => {
            clearInterval(intervalId)
        };
    }, []);
    
    const setMessagesSort = () => {
        if(messages != [] && messages != undefined) {
            if(isSort==0) {
                setMsg((prev)=>messages.filter(chat => chat.is_manager != 1 && chat.type == 1 && (!chat.is_resolved || chat.is_resolved != isResolve)).sort((a,b) => a.sentiment - b.sentiment))
            } else if(isSort ==1) {
                setMsg((prev)=>messages.filter(chat => chat.is_manager != 1 && chat.type == 1 && (!chat.is_resolved || chat.is_resolved != isResolve)))
            } else {
                setMsg((prev)=>messages.filter(chat => (!chat.is_resolved || chat.is_resolved != isResolve)))
            }
        }
    }

    const setTime = (cur,time) => {
        const sec = ((new Date(cur).getTime() - new Date(time).getTime())/1000).toFixed(0);
        return (
            sec < 60 ? sec + '초전' :
            sec < 3600 ? (sec/60).toFixed(0) + '분전' :
            sec < 3600*24 ? (sec/3600).toFixed(0) + '시간전':
            (sec/3600/24).toFixed(0) + '일전'
        )
    }

    const setSentimentColor = (sentiment) => {
        if(sentiment > 0.8) {
            return '#FF0000'
        } else if( sentiment > 0.6) {
            return '#F08A2C'
        } else if(sentiment > 0.4){
            return '#F0A700'
        } else {
            return '#008000'
        }
    }

    const setNegativeName = (sentiment) => {
        if(sentiment > 0.8) {
            return '매우 높음'
        } else if( sentiment > 0.6) {
            return '다소 높음'
        } else if(sentiment > 0.4){
            return '중간'
        } else {
            return '낮음'
        }
    }

    return (
        <>{msg != undefined && msg.map((chat, index) => {
            return <div class="sentimentSub" id={index}>
                        <div class="textLeft">{chat.nickname}</div>
                        <div class="textLeft">{chat.message}</div>
                        <div class="timeStamp textRight">{setTime(currentTime, chat.timestamp)}</div>
                        <div class="textCenter" style={{color: setSentimentColor(1-chat.sentiment)}}>{setNegativeName(1 - chat.sentiment)}</div>
                        {/*<div class="textButton">
                            <div class={chat.is_resolved ? "pushSentiment clicked" : 'pushSentiment'} onClick={()=>setIsAnswer(chat.id, chat.is_resolved)}></div>
                        </div>*/}
                    </div>
        })}</>
    )
}

export function Comment({cmt, style}) {
    const [visible, setVisible] = useState(false);
    const [comment, setComment] = useState('');
    const [leftCss, setLeftCss] = useState({})
    const [textAlign, setTextAlign] = useState('center')

    useEffect(()=>{
        if(cmt == 'sentiment') {
            setComment('최근 모든 채팅에 대한 시청자 감정의 변화 추이 입니다. 높을수록 긍정적입니다.')
        } else if(cmt == 'positive') {
            setComment('마지막 채팅에 대한 감정 분석 결과입니다.')
            setLeftCss('-200px')
        } else if(cmt == 'harm') {
            setComment('자체 수집한 여러가지 데이터를 통해 유해 판단 모델을 학습시켰습니다. 다소 부정확한 결과가 포함되어 있을 수 있으며, 원하시는 차단 사례를 전달해주시면 추가 학습 가능합니다.')
        } else if(cmt == 'chatLog') {
            setComment('해당 사용자의 채팅 중 유해하다고 판단된 채팅만 표시합니다.')
        } else if(cmt == 'chatting') {
            setComment('현재는 자체 제작 크롤러를 통해 채팅을 수집하고 있어, 실제 채팅 내역과 차이가 있을 수 있습니다. 해당 대시보드에서 입력하는 채팅은 실제 방송에 입력되지 않습니다.\n\n하단 채팅란에 입력한 메시지는 PD의 이름으로 전송됩니다.')
        } else if(cmt == 'negative') {
            setComment('채팅에 내제된 감정을 낮음부터 매우 높음까지 4단계로 나누어 표시합니다.')
            setLeftCss('-200px')
        } else if(cmt == 'log') {
            setComment('위 영역에 입력하는 채팅은 (가상) 시청자의 이름으로 전송됩니다.\n\n실제 방송에는 전송되지 않습니다.')
            setLeftCss('-200px')
        } else if(cmt == 'funnel') {
            setComment('제품 인식부터 결제로 이어지는 고객 생애주기에서, 현재 시청자들이 어떤 단계의 전환에 어려움을 겪고 있는지 인사이트를 제공합니다.')
        } else if(cmt == 'rule') {
            setComment('이벤트 채팅의 예시를 입력하면, 조건에 맞는 채팅을 자동으로 필터링합니다.\n\n예시 ⓵: 1234567890\n예시 ⓶: ‘구매인증‘과 10자리 숫자\n예시 ⓷: ‘휘’와 ‘센’을 모두 포함하는 문장')
            setTextAlign('left')
        }
    },[])

    const showTooltip = () => setVisible(true);
    const hideTooltip = () => setVisible(false);

    return (
        <div className="hoverTarget" style={style}>
            <Question className="questionMark" onMouseEnter={showTooltip} onMouseLeave={hideTooltip}/>
            <div className="hoverMessage" style={{'opacity': visible?1:0,'display': visible?'block':'none' ,'left':leftCss, 'top':'23px', textAlign: textAlign}}>{comment}</div>
        </div>
    )
}
import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

export function SentimentChart({sentiment, xData}) {
    const [series, setSeries] = useState([{
        name: '긍정도',
        data: sentiment ? sentiment : [0, 0, 0, 0, 0, 0]
    }])

    useEffect(()=>{
        if(sentiment != null) {
            //console.log(sentiment)
            const fixedSentiment = sentiment.map(element=>parseFloat(element).toFixed(2))
            setSeries(prevSentiments => {
                return [{name: '긍정도',data: fixedSentiment}]; // 업데이트된 배열 반환
            });
        }
        //console.log(series[0].data)
    },[sentiment])

    const options = {
        chart: {
            type: "area",
            height: 130,
            width: 250,
            toolbar: {
                autoSelected: "pan",
                show: false
            },
            selection: {
                enabled: false
            },
            zoom: {
                enabled: false
            }
        },
        grid: {
            show: false
        },
        yaxis: {
            stepSize: 1,
            floating: false,
            labels: {
                show: false
            },
            crosshairs: {
                show: false
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
            tooltip: {
                enabled: false
            }
        },
        xaxis: {
            stepSize: 6,
            categories: xData,
            labels: {
                show: true,
                style: {
                    fontSize: '11px'
                }
            },
            crosshairs: {
                show: false
            },
            tooltip: {
                enabled: false
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
            grid: {
                enabled: false
            }
        },
        tooltip: {
            style: {
                fontSize: '10px'
            },
            x: {
                show: false
            },
            marker: {
                show: false
            }
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: false
        },
        stroke: {
            curve: 'smooth',
            width: 1
        },
        fill: {
            type: "gradient",
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 90, 100]
            }
        }
    };

    return (
        <>
            <Chart options={options} series={series} type="area" height={90} width={292} />
        </>
    );
}


export function FunnelChart({funnel}) {
    const [series, setSeries] = useState([{
        name: '수치',
        data: funnel
    }])

    useEffect(()=>{
        if(funnel != null) {
            const fixedFunnel = funnel.map(element=>{ return element == -1 ? 0 : parseFloat(element).toFixed(2)})
            setSeries(prevSentiments => {
                return [{name: '수치',data: fixedFunnel}]; // 업데이트된 배열 반환
            });
        }
        //console.log(series[0].data)
    },[funnel])

    const options = {
        chart: {
            type: "area",
            height: 100,
            width: 200,
            toolbar: {
                autoSelected: "pan",
                show: false
            },
            selection: {
                enabled: false
            },
            zoom: {
                enabled: false
            }
        },
        grid: {
            show: false
        },
        yaxis: {
            stepSize: 0.5,
            floating: false,
            labels: {
                show: false
            },
            crosshairs: {
                show: false
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
            tooltip: {
                enabled: false
            }
        },
        xaxis: {
            stepSize: 6,
            categories: ['제품 인식','제품 이해','가격 검토','기타 요소','결제 과정','피드백'],
            labels: {
                show: false
            },
            crosshairs: {
                show: false
            },
            tooltip: {
                enabled: false
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
            grid: {
                enabled: false
            }
        },
        tooltip: {
            style: {
                fontSize: '10px'
            },
            x: {
                show: false
            },
            marker: {
                show: false
            }
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: false
        },
        stroke: {
            curve: 'smooth',
            width: 1
        },
        fill: {
            type: "gradient",
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 90, 100]
            }
        }
    };

    return (
        <>
            <Chart options={options} series={series} type="area" height={100} width={460} style={{gridColumn:'1/3', margin: '0 auto', paddingLeft: '30px'}} />
        </>
    );
}

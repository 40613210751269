import io from 'socket.io-client';



export const sio = io.connect(process.env.REACT_APP_ADDRESS, 
    {path: '/api/socket/socket.io/', 
    cors: {
        origin: "*",
        methods: ["GET", "POST"],
        transports: ['websocket', 'polling'],
        credentials: true
    },
    allowEIO3:true
});
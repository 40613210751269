import axios from 'axios';

export const URLdir = process.env.REACT_APP_ADDRESS+'/api';

export const apiFetch = async (url, data = [], method = 'GET') => {
    try {
      const options = {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
      };
  
      if (method === 'POST' || method === 'PUT') {
        options.body = JSON.stringify(data);
      }
      console.log(URLdir, url)
      const response = await fetch(URLdir+url, options);
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const result = await response.json();
      return result;
    } catch (error) {
      console.error('Fetch error:', error);
      throw error;
    }
  };

export const downloadFile = async (url) => {
  try {
    await axios.get(URLdir+url, {
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      }
    }).then((response) => {
      //console.log(response, response.data)

      const contentDisposition = response.headers['content-disposition'];
      let filename = 'download.xlsx';
      if (contentDisposition) {
        const filenameRegex = /filename\*=UTF-8''(.+)/;
        const matches = filenameRegex.exec(contentDisposition);
        if (matches && matches[1]) {
          filename = decodeURIComponent(matches[1]);
        }
        //console.log(filename, matches)
      }


      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    });
  } catch (error) {
    console.error('There has been a problem with your fetch operation:', error);
  }
};
  
import React, {useContext, useEffect, useRef, useState} from 'react';
import { apiFetch } from './API';
import { SentimentChart } from './drawGraph';
import { Comment } from './Chat';
import { MessagesContext } from './MessagesContext';


export function ChatAnalysis({roomId}) {
    const [totalComment, setTotalComment] = useState(-1)
    const [answeredComment, setAnsweredComment] = useState(-1)
    const [positivePercent, setPositivePercent] = useState(0)
    const [init, setInit] = useState([0,0,0,0,0,0])
    const [xData, setXData] = useState(['', '', '', '', '', ''])

    const {messages} = useContext(MessagesContext)
    
    const statusInterval = useRef(null);
    const currentTime = useRef(null)

    useEffect(() => {
        async function set(roomId) {
            try {
                const analy = await apiFetch(`/${roomId}/status/`)
                setInit((prev)=>addZero(analy.sentiment_avg, 'number'))
            } catch {
                console.log('[Status Error]')
            }
        }

        async function data(roomId) {
            const analy = await apiFetch(`/${roomId}/status/`)
            setTotalComment(analy.total_cnt) 
            setAnsweredComment(analy.all_inquiries)
            
            let lastMessageTime = new Date()

            if(analy.sentiment_avg.length == 0) {
                lastMessageTime = new Date()
                statusInterval.current = setInterval(()=>checkTimeAndExecute(new Date()), 600000)
            } else {
                lastMessageTime = new Date(analy.latest_timestamp);
            }
            const minutesToSubtract = 6 * 10;
            currentTime.current = new Date(lastMessageTime.getTime() - minutesToSubtract * 60000);
            let tmpList = []
            for(let i = 0 ; i < 6 ; i++) {
                tmpList.push(getHourMin());
            }

            setInit(addZero(analy.sentiment_avg, 'number'))
            setPositivePercent((analy.sentiment_avg[analy.sentiment_avg.length - 1]).toFixed(1))
            setXData(tmpList)
        }

        function checkTimeAndExecute(startTime) {
            const now = new Date();
            const elapsedMinutes = Math.floor((now - startTime) / 60000);
            const minutes = elapsedMinutes % 60;
        
            if ([0, 10, 20, 30, 40, 50].includes(minutes)) {
                set(roomId);
            }
        }

        if(roomId != undefined && roomId != -1) {
            data(roomId)
        }

        return (()=>{
            clearInterval(statusInterval.current)
        })
    },[roomId])

    useEffect(()=>{
        if(messages != undefined && messages.length != totalComment && messages.length > 0) {
            if(messages.length > 0 && !messages[0].is_manager) {
                setTotalComment((prev)=>messages.length);
                setAnsweredComment((prev)=>prev+parseInt(messages[0].type, 10));
                /*setInit((prev)=>{
                    const update = [...prev]
                    update.shift()
                    update.push(messages[0].sentiment.toFixed(2))
                    return update
                })*/
               //console.log(messages)
                setPositivePercent((prev)=>(messages[0].sentiment).toFixed(1))
            }
        }
        //console.log(totalComment)
    }, [messages.length])

    function addZero(li, type) {
        if(li.length < 6) {
            for(let i = li.length ; i < 6 ; i++) {
                if(type == 'number') {
                    li.unshift(0)
                } else if( type == 'text')  {
                    li.unshift('')
                }
            }
        } else if (li.length >= 6 && init.length >= 6) {
            if(type == 'number') {
                //nothing
            } else if( type == 'text')  {
                li.shift()
                li.push(getHourMin())
            }
        }
        return li
    }

    function getNextScheduledTime(startTime) {
        const minutes = startTime.getMinutes();
        const nextMinuteMark = [0, 10, 20, 30, 40, 50].find(minute => minute > minutes);
        //console.log(minutes)
        if (nextMinuteMark !== undefined) {
            return new Date(startTime.getFullYear(), startTime.getMonth(), startTime.getDate(), startTime.getHours(), nextMinuteMark, 0, 0);
        } else {
            // 다음 시간이 다음 시간의 첫 0분이라면
            const nextHour = new Date(startTime.getFullYear(), startTime.getMonth(), startTime.getDate(), startTime.getHours() + 1, 0, 0, 0);
            return nextHour;
        }
    }

    function getHourMin() {
        currentTime.current = getNextScheduledTime(currentTime.current)
        let hours = ('0' + currentTime.current.getHours()).slice(-2);
        let minutes = ('0' + currentTime.current.getMinutes()).slice(-2);
        return `${hours}:${minutes}`;
    }

    return (
        <div className="summaries">
            <div className="summary">
                <div className="summaryTitle">전체채팅</div>
                <div className="summaryContent" id="totalComment">{(totalComment < 0 ? 0 : totalComment) + '개'}</div>
            </div>
            <div className="summary">
                <div className="summaryTitle">문의채팅</div>
                <div className="summaryContent" id="answeredComment">{(answeredComment < 0 ? 0 : answeredComment) + '개'}</div>
            </div>
            <div className="summary">
                <div className="summaryTitle">최근 전체 채팅 감정<Comment cmt='sentiment'/></div>
                <div className="summaryContent" id="liveSentiment" style={{transform: 'translate(-20px, -20px)'}}><SentimentChart sentiment={init} xData={xData}/></div>
            </div>
            <div className="summary">
                <div className="summaryTitle">긍정도<Comment cmt='positive'/></div>
                <div className="summaryContent" id="positivePercent">{(positivePercent*100).toFixed(1) + '%'}</div>
            </div>
        </div>
    )
}
import './css/App.css';
import { Routes, Route } from 'react-router-dom';
import { DashboardPage } from './dashboardPage';
import { MonitorPage } from './monitorPage';

function App() {

  return (
    <Routes>
        <Route path={'/dashboard/:demo'} element={<DashboardPage/>}></Route>
        <Route path={'/monitor'} element={<MonitorPage/>}></Route>
    </Routes>
  );
}

export default App;
